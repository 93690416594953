import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { TimeLineItem } from 'src/app/shared/objects/components/time-line-item';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeLineService {

  constructor() { }

  /**
   * Store a new TimeLineItem
   * @param timeLineItem 
   */
  createTimeLine(timeLineItem: TimeLineItem, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, "TimeLineItem", ID.unique(), this.createPartialTimeLineItem(timeLineItem), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }

  /**
   * update an existing TimeLineItem
   * @param timeLineItem 
   */
  updateTimeLine(timeLineItem: TimeLineItem) {
    return Api.database.updateDocument(environment.database, "TimeLineItem", timeLineItem.$id, this.createPartialTimeLineItem(timeLineItem));
  }

  /**
   * This method will delete a TimeLineItem
   * @param timeLineItem 
   * @returns 
   */
  deleteTimeLine(timeLineItem: TimeLineItem) {
    return Api.database.deleteDocument(environment.database, "TimeLineItem", timeLineItem.$id);
  }

  /**
   * This method will get a list of all timeline items for the given tender id
   * @param tenderId 
   * @returns 
   */
  getTimeLineOfTender(tenderId: string) {
    return Api.database.listDocuments<TimeLineItem>(environment.database, "TimeLineItem", [Query.equal('tenderId', tenderId), Query.limit(100)]);
  }

  /**
   * This method will get a list of all timeline items
   */
  async getAllTimeLineItems() {
    var resultCount = 50;
    var resultList: TimeLineItem[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<TimeLineItem>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<TimeLineItem>(
          environment.database,
          "TimeLineItem",
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<TimeLineItem>(
          environment.database,
          "TimeLineItem",
          [Query.limit(50)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  createPartialTimeLineItem(timeLineItem: TimeLineItem): Partial<TimeLineItem> {
    return {
      description: timeLineItem.description,
      location: timeLineItem.location,
      tenderId: timeLineItem.tenderId,
      startTimeLine: timeLineItem.startTimeLine,
      endTimeLine: timeLineItem.endTimeLine
    }
  }
}
