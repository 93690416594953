import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Checklist } from 'src/app/shared/objects/components/checklist';
import { ChecklistItem } from 'src/app/shared/objects/components/checklist-item';
import { environment } from 'src/environments/environment';
import { PermissionService } from '../Permission/permission.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private permissionService: PermissionService){

  }

  addChecklist(checklist: Checklist, teamId: string) {
    return Api.database.createDocument(environment.database, "ChecklistTemplate", ID.unique(), this.createPartialChecklist(checklist, true), [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Administrator"))])
  }

  updateChecklist(checklist: Checklist) {
    return Api.database.updateDocument(environment.database, "ChecklistTemplate", checklist.$id, this.createPartialChecklist(checklist, true));
  }

  deleteChecklist(checklist: Checklist) {
    return Api.database.deleteDocument(environment.database, "ChecklistTemplate", checklist.$id);
  }

  addTenderChecklist(checklist: Checklist, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, "Checklist", ID.unique(), this.createPartialChecklist(checklist, false), [Permission.read(Role.team(teamId)), ...writePermissions])
  }

  updateTenderChecklist(checklist: Checklist) {
    return Api.database.updateDocument(environment.database, "Checklist", checklist.$id, this.createPartialChecklist(checklist, false));
  }

  deleteTenderChecklist(checklist: Checklist) {
    return Api.database.deleteDocument(environment.database, "Checklist", checklist.$id);
  }

  createPartialChecklist(checklist: Checklist, isTemplate: boolean): Partial<Checklist> {
    if(isTemplate){
      return {
        name: checklist.name?.trim(),
        tenderId: checklist.tenderId,
        establishmentId: checklist.establishmentId,
        checklistTemplateId: checklist.checklistTemplateId
      }
    } else{
      return {
        name: checklist.name?.trim(),
        tenderId: checklist.tenderId,
        establishmentId: checklist.establishmentId,
        checklistTemplateId: checklist.checklistTemplateId,
        templateId: checklist.templateId
      }
    }
  }

  addChecklistItem(checklistItem: ChecklistItem, teamId: string) {
    return Api.database.createDocument(environment.database, "ChecklistItemTemplate", ID.unique(), this.createPartialChecklistItem(checklistItem), [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Administrator"))])
  }

  updateChecklistItem(checklistItem: ChecklistItem) {
    return Api.database.updateDocument(environment.database, "ChecklistItemTemplate", checklistItem.$id, this.createPartialChecklistItem(checklistItem));
  }

  deleteChecklistItem(checklistItem: ChecklistItem) {
    return Api.database.deleteDocument(environment.database, "ChecklistItemTemplate", checklistItem.$id);
  }

  addTenderChecklistItem(checklistItem: ChecklistItem, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, "ChecklistItem", ID.unique(), this.createPartialChecklistItem(checklistItem), [Permission.read(Role.team(teamId)), ...writePermissions])
  }

  updateTenderChecklistItem(checklistItem: ChecklistItem) {
    return Api.database.updateDocument(environment.database, "ChecklistItem", checklistItem.$id, this.createPartialChecklistItem(checklistItem));
  }

  deleteTenderChecklistItem(checklistItem: ChecklistItem) {
    return Api.database.deleteDocument(environment.database, "ChecklistItem", checklistItem.$id);
  }

  createPartialChecklistItem(checklistItem: ChecklistItem): Partial<ChecklistItem> {
    return {
      name: checklistItem.name.trim(),
      value: checklistItem.value,
      type: checklistItem.type,
      cols: checklistItem.cols,
      checklistId: checklistItem.checklistId
    }
  }


  /**
   * This method will return a list with all checklists
   * @returns 
   */
  async getAllChecklists(): Promise<Checklist[]> {
    var resultCount = 50;
    var resultList: Checklist[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Checklist>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Checklist>(
          environment.database,
          "Checklist",
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Checklist>(
          environment.database,
          "Checklist",
          [Query.limit(50)]
        );
      }

      await Promise.all(retrievedObjects?.documents?.map(async c => {
        var checklistItems = await Api.database.listDocuments<ChecklistItem>(environment.database, "ChecklistItem", [Query.equal('checklistId', c.$id), Query.limit(100)]);

        c.checklistItems = checklistItems.documents;
        return Promise.resolve();
      }));

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  /**
   * This method will update a given checklist
   */
  updateChecklistAndItems(checklist: Checklist, teamId: string, establishmentId: string) {
    if (!checklist.$id) {
      return;
    }

    checklist.establishmentId = establishmentId;
    return this.updateChecklist(checklist).then(async updatedCheckList => {
      if (!updatedCheckList || !checklist.checklistItems) {
        return;
      }

      var currentChecklistItems = await Api.database.listDocuments<ChecklistItem>(environment.database, "ChecklistItemTemplate", [Query.equal('checklistId', updatedCheckList.$id)]);

      await Promise.all(currentChecklistItems.documents.map(async (checklistItem) => {
        if (checklist.checklistItems?.filter(t => t.$id == checklistItem.$id).length == 0) {
          return this.deleteChecklistItem(checklistItem);
        } else {
          return Promise.resolve();
        }
      }));

      await Promise.all(checklist.checklistItems.map(async (checklistItem) => {
        checklistItem.checklistId = updatedCheckList.$id;
        if (checklistItem.$id == "") {
          return this.addChecklistItem(checklistItem, teamId);
        } else {
          return this.updateChecklistItem(checklistItem);
        }
      }));
    });
  }

  /**
   * This method will update a given checklist
   */
  updateTenderChecklistAndItems(checklist: Checklist, teamId: string, establishmentId: string) {
    if (!checklist.$id) {
      return;
    }

    checklist.establishmentId = establishmentId;
    return this.updateTenderChecklist(checklist).then(async updatedCheckList => {
      if (!updatedCheckList || !checklist.checklistItems) {
        return;
      }

      var currentChecklistItems = await Api.database.listDocuments<ChecklistItem>(environment.database, "ChecklistItem", [Query.equal('checklistId', updatedCheckList.$id)]);

      await Promise.all(currentChecklistItems.documents.map(async (checklistItem) => {
        if (checklist.checklistItems?.filter(t => t.$id == checklistItem.$id).length == 0) {
          return this.deleteTenderChecklistItem(checklistItem);
        } else {
          return Promise.resolve();
        }
      }));

      await Promise.all(checklist.checklistItems.map(async (checklistItem) => {
        checklistItem.checklistId = updatedCheckList.$id;
        if (checklistItem.$id == "") {
          return this.addTenderChecklistItem(checklistItem, teamId, this.permissionService.getWritePermissions());
        } else {
          return this.updateTenderChecklistItem(checklistItem);
        }
      }));
    });
  }

  /**
   * This method will get all the checklists within the given establishment
   * @returns 
   */
  async getEstablishmentChecklists(establishmentId: string): Promise<Checklist[]> {
    var resultCount = 50;
    var resultList: Checklist[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Checklist>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Checklist>(
          environment.database,
          "ChecklistTemplate",
          [Query.limit(50), Query.equal('establishmentId', establishmentId), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Checklist>(
          environment.database,
          "ChecklistTemplate",
          [Query.limit(50), Query.equal('establishmentId', establishmentId)]
        );
      }

      await Promise.all(retrievedObjects?.documents?.map(async c => {
        var checklistItems = await Api.database.listDocuments<ChecklistItem>(environment.database, "ChecklistItemTemplate", [Query.equal('checklistId', c.$id), Query.limit(100)]);

        c.checklistItems = checklistItems.documents;
        return Promise.resolve();
      }));

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  /**
   * This method will get all the checklists for the given tender
   * @returns 
   */
  async getTenderChecklists(tenderId: string): Promise<Checklist[]> {
    var checklists = await Api.database.listDocuments<Checklist>(environment.database, "Checklist", [Query.equal('tenderId', tenderId), Query.limit(100)]);

    if (!checklists) {
      return [];
    }

    await Promise.all(checklists.documents.map(async (checklist) => {
      var checklistItems = await Api.database.listDocuments<ChecklistItem>(environment.database, "ChecklistItem", [Query.equal('checklistId', checklist.$id), Query.limit(100)]);
      checklist.checklistItems = checklistItems.documents;
    }));

    checklists.documents.forEach(checklist => {
      if(checklists.documents.filter(c => c.checklistTemplateId == checklist.checklistTemplateId).length > 1){
        console.error("Tender contains multiple checklist of the same template checklist.");
      }
    });

    return checklists.documents;
  }

    /**
   * This method will get all the checklists for the given template
   * @returns 
   */
    async getTemplateChecklists(templateId: string): Promise<Checklist[]> {
      var checklists = await Api.database.listDocuments<Checklist>(environment.database, "Checklist", [Query.equal('templateId', templateId), Query.limit(100)]);
  
      if (!checklists) {
        return [];
      }
  
      await Promise.all(checklists.documents.map(async (checklist) => {
        var checklistItems = await Api.database.listDocuments<ChecklistItem>(environment.database, "ChecklistItem", [Query.equal('checklistId', checklist.$id), Query.limit(100)]);
        checklist.checklistItems = checklistItems.documents;
      }));
  
      return checklists.documents;
    }
}
