import { Injectable } from '@angular/core';
import { ArrangementCategory } from 'src/app/shared/objects/components/arrangement-category';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArrangementService {

  constructor() { }

  addArrangementCategory(arrangementCategorie: ArrangementCategory, teamId: string) {
    return Api.database.createDocument(environment.database, "ArrangementCategories", ID.unique(), this.createPartialCategory(arrangementCategorie), [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Administrator"))])
  }

  updateArrangementCategory(arrangementCategorie: ArrangementCategory) {
    return Api.database.updateDocument(environment.database, "ArrangementCategories", arrangementCategorie.$id, this.createPartialCategory(arrangementCategorie));
  }

  deleteArrangementCategory(arrangementCategorie: ArrangementCategory) {
    return Api.database.deleteDocument(environment.database, "ArrangementCategories", arrangementCategorie.$id);
  }

  addArrangement(arrangement: Arrangement, teamId: string) {
    return Api.database.createDocument(environment.database, "Arrangements", ID.unique(), this.createPartialArrangement(arrangement), [Permission.read(Role.team(teamId)), Permission.write(Role.team(teamId, "Administrator"))])
  }

  updateArrangement(arrangement: Arrangement) {
    return Api.database.updateDocument(environment.database, "Arrangements", arrangement.$id, this.createPartialArrangement(arrangement));
  }

  deleteArrangement(arrangement: Arrangement) {
    return Api.database.deleteDocument(environment.database, "Arrangements", arrangement.$id);
  }

/**
 * This method will return a list with all arrangements that belong to a tender
 * @returns 
 */
  async getTenderArrangements() {
    var resultCount = 50;
    var resultList: Arrangement[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Arrangement>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Arrangement>(
          environment.database,
          "TenderArrangements",
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Arrangement>(
          environment.database,
          "TenderArrangements",
          [Query.limit(50)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  /**
   * This method will get all the arrangements within the given categories
   * @returns 
   */
  async getArrangementCategories(establishmentId: string): Promise<ArrangementCategory[]> {
    var resultCount = 50;
    var resultList: ArrangementCategory[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<ArrangementCategory>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<ArrangementCategory>(
          environment.database,
          "ArrangementCategories",
          [Query.limit(50), Query.equal('establishmentId', establishmentId), Query.orderAsc("order"), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<ArrangementCategory>(
          environment.database,
          "ArrangementCategories",
          [Query.limit(50), Query.equal('establishmentId', establishmentId), Query.orderAsc("order")]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    await this.getArrangements(resultList);

    return resultList;
  }

  private async getArrangements(categories: ArrangementCategory[]){
    var resultCount = 50;
    var resultList: Arrangement[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Arrangement>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Arrangement>(
          environment.database,
          "Arrangements",
          [Query.limit(50), Query.equal('categoryId', categories.map(c => c.$id)), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Arrangement>(
          environment.database,
          "Arrangements",
          [Query.limit(50), Query.equal('categoryId', categories.map(c => c.$id))]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    categories.forEach(category => {
      category.arrangements = resultList.filter(a => a.categoryId == category.$id);

      // Iterate over documents to set whether to use the internal description or not
      resultList?.forEach(arrangement => {
        arrangement.useInternalDescription = arrangement.internalDescription;
      });
    });

    return resultList;
  }

  private createPartialCategory(arrangementCategorie: ArrangementCategory): Partial<ArrangementCategory> {
    if (!arrangementCategorie) {
      return {};
    }
    return {
      name: arrangementCategorie.name,
      establishmentId: arrangementCategorie.establishmentId,
      order: arrangementCategorie.order
    }
  }

  private createPartialArrangement(arrangement: Arrangement): Partial<Arrangement> {
    if (!arrangement) {
      return {};
    }
    
    return {
      idInTender: arrangement.idInTender,
      name: arrangement.name,
      description: arrangement.description,
      internalDescription: arrangement.internalDescription,
      notice: arrangement.notice,
      amountOfPax: arrangement.amountOfPax,
      amountOfHour: arrangement.amountOfHour,
      price: arrangement.price,
      priceType: arrangement.priceType,
      categoryId: arrangement.categoryId,
      isCustomArrangement: arrangement.isCustomArrangement,
      hideOnKitchenSheet: arrangement.hideOnKitchenSheet,
      index: arrangement.index,
      vatPercentage: arrangement.vatPercentage,
      vatSplitPercentage: arrangement.vatSplitPercentage
    }

  }
}
