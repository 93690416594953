import { Injectable } from '@angular/core';
import { AuthService } from '../Auth/auth-service';
import { Models, Permission, Role } from 'appwrite';
import { UserPreferences } from 'src/app/shared/objects/userPreferences';
import { TeamsService } from '../Teams/teams.service';
import { InternalCommunicationService } from '../Communication/internal-communication.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  currentUser?: Partial<Models.User<UserPreferences>>; 
  currentMembership?: Models.Membership;
  activeTeamId?: string;

  constructor(private authService: AuthService, private teamService: TeamsService, private communicationService: InternalCommunicationService) {
    authService.user.subscribe(user => {
      this.currentUser = user;
      this.updateBaseValues();
    });

    communicationService.activeTeamId?.subscribe(teamId => {
      this.activeTeamId = teamId;
      this.updateBaseValues();
    })
   }

  /**
   * This method will get the current membership based on the user and active team id
   */
  updateBaseValues(){
    if(this.currentUser && this.activeTeamId){
      this.teamService.getMembership(this.activeTeamId, this.currentUser.$id!).then(membership => {
        this.currentMembership = membership;
      })
    } else {
      this.currentMembership = undefined;
    }
  }

  /**
   * This method will return a list of valid write permissions based on the current membership
   * @returns 
   */
  getWritePermissions(){
    if(!this.currentMembership){
      return [];
    }

    var permissions: string[] = []
    this.currentMembership.roles?.forEach(role => {
        if(role != "Viewer" && role != "owner"){
          permissions.push(Permission.write(Role.team(this.currentMembership!.teamId, role)));
        }
    });

    return permissions;
  }
}
