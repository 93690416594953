import { Injectable } from '@angular/core';
import { ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { Task } from 'src/app/shared/objects/task';
import { environment } from 'src/environments/environment';
import { PermissionService } from '../Permission/permission.service';
import { InternalCommunicationService } from '../Communication/internal-communication.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  taskStatusItems = [
    {
      value: "Afgerond",
      description: "Afgerond",
      iconSource: "bi bi-check-circle",
      color: "#198754"
    },
    {
      value: "Te doen",
      description: "Te doen",
      iconSource: "bi bi-hourglass-split",
      color: "#ff6600"
    },
    {
      value: "Verlopen",
      description: "Verlopen",
      iconSource: "bi bi-clock-history",
      color: "#ff3333"
    },
    {
      value: "Mee bezig",
      description: "Mee bezig",
      iconSource: "bi bi-lightning",
      color: "#e6b800"
    },
  ];

  constructor(private permissionsService: PermissionService, private communicationService: InternalCommunicationService){
    
  }

  createTask(task: Task, teamId: string) {
    // Trigger function to send create-email - Validate that module is in active Package
    if(task.joinedAssignedUserIds && this.communicationService.activePackage.getValue()?.emailCommunication){
      Api.functions.createExecution(
        'newTaskCreated',
        `${JSON.stringify(task)}`,
        false,
      );
    }

    var writePermissions = this.permissionsService.getWritePermissions();
    return Api.database.createDocument(environment.database, "Tasks", ID.unique(), this.createPartialtask(task), [Permission.read(Role.team(teamId)), ...writePermissions])
  }

  updateTask(task: Task) {
    // Trigger function to send update-email
    if(task.attachedUserIds && this.communicationService.activePackage.getValue()?.emailCommunication){
      Api.functions.createExecution(
        'taskUpdated',
        `${JSON.stringify(task)}`,
        false,
      );
    }

    return Api.database.updateDocument(environment.database, "Tasks", task.$id, this.createPartialtask(task));
  }

  deleteTask(taskId: string) {
    return Api.database.deleteDocument(environment.database, "Tasks", taskId);
  }

  /**
   * This method will get all tasks that belongs to a tender with the current id
   * @param tenderId 
   * @returns 
   */
  async getTasksOfTender(tenderId: string) {
    var resultCount = 50;
    var resultList: Task[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Task>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Task>(
          environment.database,
          "Tasks",
          [Query.limit(50), Query.equal('tenderId', tenderId), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Task>(
          environment.database,
          "Tasks",
          [Query.limit(50), Query.equal('tenderId', tenderId)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  /**
   * This method will get all tasks from a establishment
   * @param establishmentId 
   * @returns 
   */
  async getAllTasksForEstablishment(establishmentId: string) : Promise<Task[]>{
    var resultCount = 50;
    var resultList: Task[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Task>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Task>(
          environment.database,
          "Tasks",
          [Query.limit(50), Query.equal('establishmentId', establishmentId), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Task>(
          environment.database,
          "Tasks",
          [Query.limit(50), Query.equal('establishmentId', establishmentId)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }


  createPartialtask(task: Task): Partial<Task> {
    return {
      tenderId: task.tenderId,
      establishmentId: task.establishmentId,
      joinedAssignedUserIds: task.joinedAssignedUserIds,
      status: task.status,
      creationDate: task.creationDate,
      deadlineDate: task.deadlineDate,
      taskName: task.taskName,
      taskDescription: task.taskDescription,
      archived: task.archived,
    };
  }

  /**
   * This method will return the icon resource based on the status
   */
  getStatusIcon(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.taskStatusItems.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].iconSource;
  }

  /**
  * This method will return the color of a status
  * @param status 
  * @returns 
  */
  getStatusColor(status: string) {
    if (!status) {
      return;
    }

    var statusResource = this.taskStatusItems.filter(s => s.value == status);

    if (!statusResource || statusResource?.length == 0) {
      return;
    }

    return statusResource[0].color;
  }

}