import { Injectable } from '@angular/core';
import { Tender } from 'src/app/shared/objects/tender';
import { TenderService } from '../Tender/tender.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  currentVersion: string = "1.2.7";
  releaseDateVersion: string = "08-12-2024";

  constructor(private tenderSerivce: TenderService) { }

  checkForUpgrades(tender: Tender, teamId: string){
    var upgraded = false;

    // Version 1.1.3
    if(this.upgradeTo_1_1_3(tender)){
      upgraded = true;
    }
    
    var splittedCurrentVersion = this.currentVersion.split(".");
    if(this.currentVersionIsLower(tender.jgtVersion, Number(splittedCurrentVersion[0]), Number(splittedCurrentVersion[1]), Number(splittedCurrentVersion[2]))){
      tender.jgtVersion = this.currentVersion;
      upgraded = true;
    }
    
    if(upgraded){
      this.tenderSerivce.updateTender(tender, teamId);
    }

    return upgraded;
  }

  /**
   * This method will upgrade a tender to the level of: version 1.1.3
   * [0] --> Price type changed from p.s. to p.p. and p.s. was added as new price type
   * @param tender 
   */
  private upgradeTo_1_1_3(tender: Tender){
    if(!this.currentVersionIsLower(tender.jgtVersion, 1, 1, 3)){
      return false;
    }

    var changes = false;
    tender.arrangements?.forEach(arrangement => {
      // If pricetype is p.s. then convert to p.p.
      if(arrangement.priceType == "p.s." && arrangement.amountOfPax && arrangement.amountOfPax > 0){
        arrangement.priceType = "p.p.";
        changes = true;
      }
    })
    
    return changes;
  }

  /**
   * This method will check if the current version is lower then the given version to compare with
   * @param currentVersion 
   * @param compareWithMajor 
   * @param compareWithMinor 
   * @param compareWithBuild 
   */
  private currentVersionIsLower(currentVersion: string, compareWithMajor: number, compareWithMinor: number, compareWithBuild: number){
    var splittedCurrentVersion = currentVersion.split('.');

    if(splittedCurrentVersion.length != 3){
      return false; // FORMAT ERROR
    }

    if(Number(splittedCurrentVersion[0]) < compareWithMajor){
      return true;
    }

    if(Number(splittedCurrentVersion[0]) <= compareWithMajor && Number(splittedCurrentVersion[1]) < compareWithMinor){
      return true;
    }

    if(Number(splittedCurrentVersion[0]) <= compareWithMajor && Number(splittedCurrentVersion[1]) <= compareWithMinor && Number(splittedCurrentVersion[2]) < compareWithBuild){
      return true;
    }
    
    return false;
  }
}
